import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Team_1 from '../assets/images/team1.png'
import Http_Api from '../Http_Api';
import dataContext from '../context/data/dataContext';
export default function Executive_Director() {
    const windoSCrollCount = useRef(0)
    const context = useContext(dataContext);
    const { data,multi_image_url} = context

    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

    }, [])


    var Executive_Director;
    if (data.length > 0) {
        var { Executive_Director } = data[0];
    }
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/400x400/?news)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>বানি</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Shop</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                                        <Link to={'/'} className="breadcrumbs__item is-active">বানি</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Executive_Director">
                <div className="container">
                    <div style={{ margin: "5% 0" }}>
                        {Executive_Director !== undefined ? Executive_Director.map((data) => (
                            <>
                                <h1 className='text-center'>বানি</h1>
                                <div className="row ">
                                    <div className="col-lg-4 p-5  text-center" style={{ height: "6rem", width: "6rem" }}>
                                        <img style={{ borderRadius: "2rem", minHeight: "100%", minWidth: "100%" }} src={data.image !== null && data.image.length > 0 ? (`${multi_image_url}/uploads/speech/${data.image}`) : (Team_1)} alt="" />
                                        {/* <div className=' mt-3 rounded py-1 text-left bg-white' style={{ fontSize: "0.8rem", }}>


                                    <span className=' row mx-1'><span className='col-3 font-weight-bold'>Name:</span> <span className='col'>{data_People.length > 0 ? ((data_People[0].title).split(/,+/)[0]) : "please wait ..."}</span></span>
                                    <span className=' row  mx-1'><span className='col-3 font-weight-bold'>Age:</span> <span className='col'>{data_People.length > 0 ? ((data_People[0].title).split(/,+/)[1]) : "please wait ..."}</span></span>
                                    <p className='mx-3'>
                                        <span className='font-weight-bold ' style={{ marginRight: "0.8rem" }}>Other Details:</span>
                                        <span className=''>{data_People.length > 0 ? ((data_People[0].title).split(/,+/)[2]) : "please wait ..."}</span>
                                    </p>
                                </div> */}
                                    </div>
                                    <div className="col-lg-8">
                                        <p className='py-5' style={{ textAlign: "justify" }}>{data.description}</p>
                                    </div>
                                </div>
                            </>
                        )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                    </div >
                </div >
            </section>
        </>
    )
}
