import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import D_1 from "../assets/images/d1.png";
import dataContext from '../context/data/dataContext';
export default function News() {
    const context = useContext(dataContext);
    const { data, getData, StorageUrl_logo, StorageUrl_Image, multi_image_url } = context;
    const windoSCrollCount = useRef(0)
    useEffect(() => {

        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        vissible_first()
        return () => {
            vissible_first()
        }
    }, [])

    const Hidden_Vissible = (element, id) => {


        let myelement = element.target.closest('.btn');
        // console.log(myelement, " and ", id)
        let b = document.getElementById(id);

        let mybtn = document.getElementsByClassName('mybtn');
        for (let index = 0; index < mybtn.length; index++) {
            const element = mybtn[index];
            if (element.classList.contains('btn-outline-success')) {
                element.classList.remove('btn-outline-success', 'text-success', 'bg-white')
                element.classList.add('bg-secondary-subtle')
            }
        }
        if (myelement.classList.contains('bg-secondary-subtle')) {
            myelement.classList.remove('bg-secondary-subtle')
            myelement.classList.add('btn-outline-success', 'text-success', 'bg-white')
        }
        let News_box = document.getElementsByClassName('News_box');
        for (let index = 0; index < News_box.length; index++) {
            const element = News_box[index];
            if (element.classList.contains('d-block')) {
                element.classList.add('d-none');
                element.classList.remove('d-block');
            }
        }
        if (b.classList.contains('d-none')) {
            b.classList.remove('d-none')
            b.classList.add('d-block')
        }

    }
    var differences;
    if (data.length > 0) {
        var { differences } = data[0];
    }

    if (differences) {
        let s1 = [];
        Object.keys(differences).map((key) => {
            s1.push(differences[key])
        })
        differences = s1

    }
    const vissible_first = () => {

        let a = document.getElementsByClassName('mybtn')[0]
        let b = document.getElementsByClassName('News_box')[0]

        if (a !== undefined && b !== undefined) {
            a.classList.remove('bg-secondary-subtle')
            a.classList.add('btn-outline-success', 'bg-white', 'text-success')
            b.classList.remove('d-none')
            b.classList.add('d-block')
        }
    }
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"
                    style={{ backgroundImage: `url('https://source.unsplash.com/random/720x420?islamic')` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>যে কারণে আমরা ব্যতিক্রম</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                        <Link to={'/LatestNews'} className="breadcrumbs__item is-active">যে কারণে আমরা ব্যতিক্রম</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='blog'>
                <div className="container">
                    {/* <div className="section-title">
                        <h1>Read New News</h1>
                    </div> */}
                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="d-flex flex-wrap justify-content-center mb-5" >
                                {differences !== undefined && differences !== null && differences.length > 0 ? differences.map((data) => (
                                    <span key={data.id} style={{ width: '100%' }} className="d-flex justify-content-between  py-3 mb-2 mybtn  font-weight-bold   btn bg-secondary-subtle btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, data.id) }}>
                                        <span>{data.title.length < 23 ? (`${data.title}`) : (`${data.title}...`)}
                                        </span>
                                        <span style={{ width: "1.5rem" }}>

                                            <img style={{ width: "100%" }} src={(data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/difference/${data.photo}`) : (D_1))} alt="" />
                                        </span>
                                    </span>

                                )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                                {/* <span style={{ width: '100%' }} className="d-flex justify-content-between py-3 mb-2 mybtn  font-weight-bold bg-secondary-subtle btn  btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, 'asdf') }}>
                                    <span>Large Button</span>
                                    <span>
                                        <i className="fa-solid fa-image"></i>
                                    </span>
                                </span>
                                <span style={{ width: '100%' }} className="d-flex justify-content-between py-3 mb-2 mybtn  font-weight-bold bg-secondary-subtle btn  btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, '2') }}>
                                    <span>Large Button</span>
                                    <span>
                                        <i className="fa-solid fa-image"></i>
                                    </span>
                                </span>
                                <span style={{ width: '100%' }} className="d-flex justify-content-between py-3 mb-2 mybtn  font-weight-bold bg-secondary-subtle btn  btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, '3') }}>
                                    <span>Large Button</span>
                                    <span>
                                        <i className="fa-solid fa-image"></i>
                                    </span>
                                </span> */}
                            </div>
                        </div>
                        <div className="News_main_box col-lg-8 text-left">
                            {differences !== undefined && differences !== null && differences.length > 0 ? differences.map((data) => (
                                <div id={data.id} key={data.id} className='News_box d-none'>
                                    <div className='d-flex flex-column fadeIN'>
                                        <div className='text-center'>
                                            <img src={(data.photo !== null && data.photo.length > 0 ? (`${multi_image_url}/uploads/difference/${data.photo}`) : (D_1))} alt="" />
                                        </div>
                                        <div className='pe-5 mt-4'>
                                            <h3>{data.title}</h3>
                                            <p className='pe-5'>{data.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                            {/*
                             <div id='asdf' className='News_box d-none'>
                                <div className='d-flex flex-column fadeIN'>
                                    <div className=''>
                                        <img src="https://source.unsplash.com/random/780x360?newspaper" alt="" />
                                    </div>
                                    <div className='pe-5 mt-4'>
                                        <h3>Heading 2</h3>
                                        <p className='ps-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quibusdam, fugiat modi veniam illum tempore quam officiis rem porro laboriosam nostrum at impedit temporibus repellendus dolore eligendi omnis neque explicabo dolor quas nihil labore? Eligendi ex maxime alias rem mollitia temporibus! Alias magni minus exercitationem eaque quos aut ipsum laboriosam!</p>
                                    </div>
                                </div>
                            </div>
                            <div id='2' className='News_box d-none'>
                                <div className='d-flex flex-column fadeIN'>
                                    <div className=''>
                                        <img src="https://source.unsplash.com/random/780x360?report" alt="" />
                                    </div>
                                    <div className='pe-5 mt-4'>
                                        <h3>Heading 3</h3>
                                        <p className='pe-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quibusdam, fugiat modi veniam illum tempore quam officiis rem porro laboriosam nostrum at impedit temporibus repellendus dolore eligendi omnis neque explicabo dolor quas nihil labore? Eligendi ex maxime alias rem mollitia temporibus! Alias magni minus exercitationem eaque quos aut ipsum laboriosam!</p>
                                    </div>
                                </div>
                            </div>
                            <div id='3' className='News_box d-none'>
                                <div className='d-flex flex-column fadeIN'>
                                    <div className=''>
                                        <img src="https://source.unsplash.com/random/780x360?news" alt="" />
                                    </div>
                                    <div className='pe-5 mt-4'>
                                        <h3>Heading 4</h3>
                                        <p className='pe-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quibusdam, fugiat modi veniam illum tempore quam officiis rem porro laboriosam nostrum at impedit temporibus repellendus dolore eligendi omnis neque explicabo dolor quas nihil labore? Eligendi ex maxime alias rem mollitia temporibus! Alias magni minus exercitationem eaque quos aut ipsum laboriosam!</p>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}
