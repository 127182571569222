import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { useParams } from 'react-router-dom'
import dataContext from '../context/data/dataContext';
import Team_1 from '../assets/images/team1.png'
import { Link } from 'react-router-dom'

export default function Speech() {
    let { id } = useParams()
    const windoSCrollCount = useRef(0)
    const context = useContext(dataContext);
    const { data, multi_image_url } = context

    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

    }, [])

    var speech;
    if (data.length > 0) {
        var { speech } = data[0];
    }
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/400x400/?islamic)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>বাণী</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                        <Link to={'/'} className="breadcrumbs__item ">বাণী</Link>
                                        {speech !== undefined && speech !== null && speech.length > 0 && speech.map((data) => (
                                            data.id == id &&
                                            <>
                                                <Link to={`/speech/${data.id}`} className="breadcrumbs__item is-active" >{data.message_type}</Link>
                                            </>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Chief_Director">
                <div className="container">
                    <div style={{ margin: "5% 0" }}>
                        {speech !== undefined && speech !== null && speech.length > 0 ? speech.map((data) => (
                            data.id == id &&
                            <>
                                <h1 className='text-center'>{data.message_type} এর বাণী</h1>
                                <div className="row ">
                                    <div className="col-lg-4 p-5  text-center">
                                        <div className='text-center mx-auto' style={{ height: "17rem", width: "17rem" }}>
                                            <img style={{ borderRadius: "2rem", minHeight: "100%", minWidth: "100%" }} src={data.image !== null && data.image.length > 0 ? (`${multi_image_url}/uploads/speech/${data.image}`) : (Team_1)} alt="" />
                                            <div className='mt-3'>
                                                <h5>{data.person_name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <p className='py-5' style={{ textAlign: "justify" }}>{data.description}</p>
                                    </div>
                                </div>
                            </>
                        )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                    </div >
                </div >
            </section >
        </>
    )
}
