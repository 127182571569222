import React, { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import LightGallery from 'lightgallery/react';
import Gallary_1 from "../assets/images/gallary_1.jpeg"
import Gallary_2 from "../assets/images/gallary_2.jpeg"
import Gallary_3 from "../assets/images/gallary_3.jpeg"
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import dataContext from '../context/data/dataContext';


export default function Gallary() {
  const context = useContext(dataContext);
  const { data, multi_image_url } = context
  const windoSCrollCount = useRef(0)
  useEffect(() => {

    if (windoSCrollCount.current === 0) {
      // console.log('asdfasfsa')
      window.scrollTo({ top: 0, behavior: "smooth" })
      windoSCrollCount.current = 1
    }
    let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
    return () => {

    }
  }, [])
  const onInit = () => {
    // console.log('lightGallery has been initialized');
  };

  const Team_vissible = (e, name) => {
    let element = e.target.closest('.tablinks')
    let tablinks = document.getElementsByClassName('tablinks')
    for (let index = 0; index < tablinks.length; index++) {
      const element = tablinks[index];
      if (element.classList.contains('active')) {
        element.classList.remove('active');
      }
    }
    if (!element.classList.contains('active')) {
      element.classList.add('active');
    }


    let employee = document.getElementById(name);
    let employee_all = document.getElementsByClassName('team_member_by_catagory');
    for (let index = 0; index < employee_all.length; index++) {
      const element = employee_all[index];
      if (element.classList.contains('d-block')) {
        element.classList.add('d-none')
        element.classList.remove('d-block')
      }
    }
    // setUser_catagory(name);
    if (employee.classList.contains('d-none')) {
      employee.classList.add('d-block')
      employee.classList.remove('d-none')
    }

  }

  var gallary;
  if (data.length > 0) {
    var { gallary } = data[0];
    let a = document.getElementsByClassName("tablinks")[0];
    let b = document.getElementsByClassName("team_member_by_catagory")[0];
    if (a !== undefined && b !== undefined) {
      a.classList.add('active');
      b.classList.remove('d-none');
      b.classList.add('d-block');
    }

  }

  return (
    <>

      <section id="page-banner">
        <div className="banner-inner"

          style={{ backgroundImage: `url(https://source.unsplash.com/random/400x400/?news)` }}>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner-contents">
                  <h1 className='d-block'>গ্যালারি</h1>
                  <nav className="breadcrumbs " style={{ opacity: "1" }}>
                    <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                    {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                    {/* <Link to={'/'} className="breadcrumbs__item">Cart</Link> */}
                    <Link to={'/gallary'} className="breadcrumbs__item is-active">গ্যালারি</Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="gallary" className='mx-auto'>

        <div className="container my-5">
          <h4 className='text-center'>We work for your satisfaction</h4>
          <div className="tab text-center my-5">
            <span className="tablinks btn btn-outline-success mx-2 active"
              onClick={(e) => { Team_vissible(e, 'all') }}>সব</span>

            {gallary !== undefined && gallary !== null && gallary.length > 0 && gallary.map((data) => (
              <span className="tablinks btn btn-outline-success mx-2 "
                onClick={(e) => { Team_vissible(e, `${data.title}`) }}>{data.title}</span>
            ))}
            {/* <span className="tablinks btn btn-outline-success mx-2 "
              onClick={(e) => { Team_vissible(e, 'advisoryBorde') }}>উপদেষ্টামন্ডলী</span>
            <span className="tablinks btn btn-outline-success mx-2"
              onClick={(e) => { Team_vissible(e, 'official') }}>কর্মকর্তা</span>
            <span className="tablinks btn btn-outline-success mx-2"
              onClick={(e) => { Team_vissible(e, 'teacher') }}>শিক্ষক</span>
            <span className="tablinks btn btn-outline-success mx-2"
              onClick={(e) => { Team_vissible(e, 'employee') }}>কর্মচারী</span> */}
          </div >
          <div id='all' className='team_member_by_catagory d-block'>
            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
            >
              {gallary !== undefined && gallary !== null && gallary.length > 0 ? gallary.map((data) => (
                <>
                  {data.gallary_images !== null && data.gallary_images.length > 0 && data.gallary_images.map((data2) => (
                    <>
                      {data2.status === 1 &&
                        <a href={`${multi_image_url}/uploads/gallery/${data2.image}`} >
                          <img style={{ margin: "1rem", width: "21.1rem" }} alt={data2.caption} src={`${multi_image_url}/uploads/gallery/${data2.image}`} />
                        </a >
                      }
                    </>
                  ))}
                </>
              )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}



            </LightGallery>
          </div>
          {gallary !== undefined && gallary !== null && gallary.length > 0 ? gallary.map((data) => (
            <div id={`${data.title}`} className='team_member_by_catagory d-none'>
              <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
              >
                {data.gallary_images !== null && data.gallary_images.length > 0 ? data.gallary_images.map((data2) => (
                  <>
                    {data2.status === 1 &&
                      <a href={`${multi_image_url}/uploads/gallery/${data2.image}`} >
                        <img style={{ margin: "1rem", width: "21.1rem" }} alt={data2.caption} src={`${multi_image_url}/uploads/gallery/${data2.image}`} />
                      </a >
                    }
                  </>
                )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
                {/* <a href="/static/media/gallary_1.1820eb133274d8c3faf2.jpeg">
                  <img style={{ margin: "1rem", width: "21.1rem" }} alt="img1" src={Gallary_1} />
                </a>
                <a href="/static/media/gallary_1.1820eb133274d8c3faf2.jpeg">
                  <img style={{ margin: "1rem", width: "21.1rem" }} alt="img1" src={Gallary_1} />
                </a> */}




              </LightGallery>
            </div>
          )) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}
          {/* <div id='advisoryBorde' className='team_member_by_catagory d-none'>
          </div>
          <div id='official' className='team_member_by_catagory d-none'>
          </div>
          <div id='teacher' className='team_member_by_catagory d-none'>
          </div>
          <div id='employee' className='team_member_by_catagory d-none'>
          </div> */}
        </div>
      </section >
    </>
  )
}
