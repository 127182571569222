// OWL-CAROUSEL
import jQuery from "jquery";
import $ from "jquery";
// $('.differences-carousel').owlCarousel({
//   smartSpeed: 450,
//   mouseDrag: true,
//   touchDrag: true,
//   loop: true,
//   margin: 0,
//   nav: false,
//   // navText: ["<img src='./assets/images/arrow-left.png'>","<img src='./assets/images/arrow-right.png'>"],
//   dots: true,
//   autoplay: false,
//   autoplayTimeout: 3000,
//   smartSpeed: 1000,
//   responsive: {
//     0: {
//       items: 1,
//     },
//     768: {
//       items: 2,
//     },
//     992: {
//       items: 3,
//     },
//   }
// });
// OWL-CAROUSEL-END
// OWL-CAROUSEL
// $('.team-carousel').owlCarousel({
//   smartSpeed: 450,
//   mouseDrag: true,
//   touchDrag: true,
//   loop: true,
//   margin: 20,
//   nav: false,
//   // navText: ["<img src='./assets/images/arrow-left.png'>","<img src='./assets/images/arrow-right.png'>"],
//   dots: true,
//   autoplay: false,
//   autoplayTimeout: 3000,
//   smartSpeed: 1000,
//   responsive: {
//     0: {
//       items: 1,
//     },
//     768: {
//       items: 2,
//     },
//     992: {
//       items: 3,
//     },
//     1200: {
//       items: 4,
//     },
//   }
// });
// OWL-CAROUSEL-END
// OWL-CAROUSEL
// $('.testimonial-carousel').owlCarousel({
//   smartSpeed: 450,
//   mouseDrag: true,
//   touchDrag: true,
//   loop: true,
//   margin: 20,
//   nav: false,
//   // navText: ["<img src='./assets/images/arrow-left.png'>","<img src='./assets/images/arrow-right.png'>"],
//   dots: true,
//   autoplay: false,
//   autoplayTimeout: 3000,
//   smartSpeed: 1000,
//   responsive: {
//     0: {
//       items: 1,
//     },
//     768: {
//       items: 2,
//     }
//   }
// });
// OWL-CAROUSEL-END
// OWL-CAROUSEL
// $('.hero-carousel').owlCarousel({
//   smartSpeed: 450,
//   mouseDrag: true,
//   touchDrag: true,
//   loop: true,
//   nav: false,
//   // navText: ["<img src='./assets/images/arrow-left.png'>","<img src='./assets/images/arrow-right.png'>"],
//   dots: true,
//   autoplay: false,
//   autoplayTimeout: 3000,
//   smartSpeed: 1000,
//   responsive: {
//     0: {
//       items: 1,
//     }
//   }
// });
// OWL-CAROUSEL-END
// HAMBURGER-AND-MENU-FUNCTION
$(document).ready(function () {
  $('.nav-header').click(function () {
    $('.nav-header').toggleClass('active');
    $('.menu-trigger').toggleClass('visible');
    // $('.collapse-nav').toggle('boxopened', 'easeInQuad');
    $('.collapse-nav').toggleClass('slide');
  });
  $('.menu-trigger').click(function () {
    $('.nav-header').toggleClass('active');
    $('.menu-trigger').toggleClass('visible');
    // $('.collapse-nav').toggle('boxopened', 'easeInQuad');
    $('.collapse-nav').toggleClass('slide');
  });

  $('.menu-item-has-children').on('click', (event) => {
    $(event.target).siblings('.sub-menu')
      .toggleClass('is-open');
  });
  //  if you want to remove the dropdown by clicking anywhere at the display then try this..
  $(document).click(function (e) {
    $('.menu-item-has-children')
      .not($('.menu-item-has-children').has($(e.target)))
      .children('.sub-menu')
      .removeClass('is-open');
  });
  // DROPDOWN-REMOVE-BY-CLICKING-ANYWHERE-END
});
// HAMBURGER-AND-MENU-FUNCTION-END


// scroll-effect
$(window).on('scroll', function () {
  if ($(window).scrollTop() > 200) {
    $('header').addClass('sticky');
  }

});

$(window).on('scroll', function () {
  if ($(window).scrollTop() < 200) {
    $('header').removeClass('sticky');
  }

});

// Add Your Scripts here
// SMOOTH SCROLLING
// $('a[href*="#"]')
//   // Remove links that don't actually link to anything
//   .not('[href="#"]')
//   .not('[href="#0"]')
//   .click(function (event) {
//     // On-page links
//     if (
//       location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
//       &&
//       location.hostname == this.hostname
//     ) {
//       // Figure out element to scroll to
//       var target = $(this.hash);
//       target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
//       // Does a scroll target exist?
//       if (target.length) {
//         // Only prevent default if animation is actually gonna happen
//         event.preventDefault();
//         $('html, body').animate({
//           scrollTop: target.offset().top
//         }, 1000, function () {
//           // Callback after animation
//           // Must change focus!
//           var $target = $(target);
//           $target.focus();
//           if ($target.is(":focus")) { // Checking if the target was focused
//             return false;
//           } else {
//             $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
//             $target.focus(); // Set focus again
//           };
//         });
//       }
//     }
//   });
// SMOOTH SCROLLING END

// jQuery(document).ready(function ($) {
//   $('#fullpage').fullpage({
//     //options here
//     scrollingSpeed: 1000,
//     navigation: true,
//     slidesNavigation: true
//   });
//   //methods
//   $.fn.fullpage.setAllowScrolling(true);
// });