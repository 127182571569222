import React, { useEffect, useRef } from 'react'

export default function Read_more() {
    const windoSCrollCount = useRef(0)
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }

    }, [])
    return (
        <>
            <section className="read_more_section">
                <div className="container my-5">

                    <div className="row">
                        <div className="col col-lg-8">
                            <div className="heading_read_more">
                                <h4 className="text-left font-weight-normal head_line ">Topic Name of Read More</h4>
                                <div className="date_group">
                                    <img src="../assets/images/calendar-days-solid.svg"
                                        alt="" /><span></span>
                                    <span>2 April 2023</span>
                                </div>
                            </div>

                            <img className=" img-fluid  align-middle"
                                src="https://source.unsplash.com/random/400x400?nature "
                                alt="" />
                            <div className="content mt-5">
                                <h6 className="my-1">There are a few things to verify:</h6>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam hic magnam maxime modi provident,
                                distinctio eligendi facilis ea nostrum cupiditate officia repellat magni amet. Quaerat
                                repudiandae in dolorem nihil magnam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Earum reiciendis porro architecto tempora ut optio et aspernatur incidunt voluptas aut expedita
                                odit corporis nam eligendi, officiis modi esse harum nisi adipisci accusamus saepe a id!
                                Obcaecati autem qui atque voluptate eos eligendi quam culpa voluptatum aliquam optio quidem sunt
                                enim asperiores mollitia, odio temporibus incidunt!
                            </div>
                        </div>
                        <div className="col col-lg-3">
                            <div className="content mt-5">
                                <h4 className="text-center font-weight-normal"> Recent Post</h4>
                                <hr className="hr1" />
                                <ul className="">
                                    <li> Lorem ipsum dolor, sit amet consectetur adipisicing elit. R</li>
                                    <li>
                                        <hr className="hr2" />
                                    </li>
                                    <li> Lorem ipsum dolor, sit amet consectetur adipisicing elit. R</li>
                                    <li>
                                        <hr className="hr2" />
                                    </li>
                                    <li> Lorem ipsum dolor, sit amet consectetur adipisicing elit. R</li>
                                    <li>
                                        <hr className="hr2" />
                                    </li>
                                    <li> Lorem ipsum dolor, sit amet consectetur adipisicing elit. R</li>

                                </ul>
                            </div>
                        </div>
                    </div>




                </div>
            </section >
        </>
    )
}
