import React from 'react'

export default function calander() {
    return (
        <section id="demos">
            <div class="section-content">
                <p class="section-title --shrt">evocalendar in action!</p>
                <div class="action-buttons">
                    <p class="section-desc">Experience evocalendar in every theme</p>
                    <button class="btn-action active"
                        data-set-theme="Default">DEFAULT</button>
                    <button class="btn-action"
                        data-set-theme="Midnight Blue">MIDNIGHT BLUE</button>
                    <button class="btn-action"
                        data-set-theme="Royal Navy">ROYAL NAVY</button>
                    <button class="btn-action"
                        data-set-theme="Orange Coral">ORANGE CORAL</button>
                </div>
                <div class="console-log">
                    <div class="log-content">
                        <div class="--noshadow"
                            id="demoEvoCalendar"></div>
                    </div>
                </div>
                <div class="action-buttons">
                    <button class="btn-action"
                        id="addBtn">ADD EVENT</button>
                    <button class="btn-action"
                        id="removeBtn"
                        disabled>REMOVE EVENT</button>
                </div>
            </div>
        </section>
    )
}
