import React from 'react'

export default function Login() {
    return (
        <>

            <section id="form_bg_trasnparennt">
                <div id="container_form"
                    className="container  ">
                    <div className="row mx-auto ">
                        <div className="details brand_details col-lg-6 text-center">
                            <span className="logo_container">
                                <img className="bizzself_logo "
                                    src={''} />
                            </span>
                            <div className="content">
                                <p>BizzSelf is a dynamic and intuitive Business Management ERP (Enterprise Resource Planning) software
                                    designed to revolutionize the way organizations operate. Offering an all-encompassing solution, BizzSelf
                                    seamlessly integrates various facets of business management, from finance and human resources to supply
                                    chain and customer relations. <br /><br />
                                    With its user-friendly interface, BizzSelf simplifies complex workflows, automates routine tasks, and
                                    centralizes data, providing businesses with a holistic view of their operations. This comprehensive ERP
                                    software empowers decision-makers with real-time insights, fostering data-driven strategies that drive
                                    efficiency and innovation.

                                    <br />
                                    <span id="webshine_link_container"
                                        className="text-center nav-link  imprtant_text_deem">A Product Of <a className="webshine_link"
                                            href="">Web Shinez Technology</a></span>
                                </p>
                            </div>
                        </div>
                        <div className="details  form_section col-lg-6  ">
                            <div className="form_section_inner ">
                                <p className="text-secondary">Welcome to BizzSelf</p>
                                <h1 className="mb-4">Login</h1>
                                <form>
                                    <div className="mb-3">
                                        <label for="exampleInputEmail1"
                                            className="form-label">Username</label>
                                        <input type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Your Username" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleInputPassword1"
                                            className="form-label">Password</label>
                                        <input type="password"
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="Enter Your Password" />
                                    </div>
                                    <div className="mb-3 form-check d-flex justify-content-between">
                                        <div>
                                            <input type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                style={{ border: "0.1px solid white", background: " transparent" }}
                                            />
                                            <label className="form-check-label " style={{ fontWeight: "lighter" }}
                                                for="exampleCheck1">Remember Me</label>
                                        </div>
                                        <div>
                                            <p><a className="nav-link fst-italic " style={{ fontWeight: "lighter" }}
                                                href="">Forget your password?</a></p>
                                        </div>
                                    </div>
                                    <div className="mb-3 ">
                                        <button type="submit"
                                            className="btn_custom px-5 ">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
