import axios from "axios";

export default axios.create({
    baseURL: process.env.REACT_APP_API_KEY,
    withCredentials: false,
    header: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;application/json'
    }
})