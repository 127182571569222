import React, { useContext, useEffect, useRef, useState } from 'react'
import Contact from '../assets/images/contact.jpg';
import News_1 from "../assets/images/news1.png"
import { Link } from 'react-router-dom';
import Http from '../Http_Api';
import dataContext from '../context/data/dataContext';
export default function NewsAll() {
    const context = useContext(dataContext);
    const { data, multi_image_url } = context;
    const windoSCrollCount = useRef(0)

    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

    }, [])


    var news;
    if (data !== null && data !== undefined && data.length > 0) {
        var { news } = data[0];
    }
    if (news !== undefined && news.length > 0) {
        let val = [];
        news.map((data) => {
            val.push(data);
        })
        news = val;
    }
    // console.log(dataNews_inner)
    // if (dataNews_inner.length >0) {
    //     console.log(dataNews_inner)
    // }
    // else{
    //     data_news_inner()
    // }
    return (

        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url('https://source.unsplash.com/random/720x420?islamic')` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="banner-contents">
                                    <h1 className='d-block'>নিউজ</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                        {/* <Link to={'/'} className="breadcrumbs__item">Cart</Link> */}
                                        <Link to={'/NewsAll'} className="breadcrumbs__item is-active">নিউজ</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="newsAll" style={{ margin: "5% 0" }} >
                <div className="container">
                    <div className="section-title">
                        <h2>নিউজ</h2>
                        <p>কওমী মাদরাসা শিক্ষা বোর্ড (বেফাক) এর কেন্দীয় পরীক্ষায় মেধা তালিকায় দ্বিতীয় ও তৃতীয় স্থান অধিকার সহ হুফফাজুল কুরআন ফাউন্ডেশনের প্রতিযোগিতায় প্রতিষ্ঠানটির অর্জন হয়েছে অসংখ্য পুরুষ্কার</p>
                    </div>
                    <div className="row ">
                        {news !== undefined && news !== null && news.length > 0 ? (news.map((data) => (
                            <>
                                {data.is_view_on_web == 1 && (
                                    <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                                        <div className="single-news">
                                            <div className="news-image"
                                                style={{ backgroundImage: `url(${multi_image_url}/uploads/news/${data.image})` }}></div>

                                            {/* <div className="news-info row  bg-success text-white py-4 " style={{ padding: "0 2.5rem" }}>
                                                <div className='col-1'>
                                                    <div><i class="fa-solid fa-location-dot"></i></div>
                                                    <div><i class="fa-solid fa-calendar-days"></i></div>
                                                </div>
                                                <div className='col-10'>
                                                   
                                                    <div>{data.event_place}</div>
                                                    <div>
                                                        <span>From <span>{data.event_from} </span></span> <br />
                                                        <span>TO <span>{data.event_to} </span></span>
                                                    </div>
                                                </div>

                                            </div> */}
                                            <div className="news-details">
                                                <h4>{data.title}</h4>
                                                <p>{data.news.slice(0, 180)} </p>
                                                <Link to={`/News/${data.id}`}>বিস্তারিত পড়ুন</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </>
                        ))) : (<><h3 className='text-center m-auto'>কোন তথ্য নেই</h3></>)}

                        {/* <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/Event/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/Event/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/Event/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/Event/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/Event/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/Event/id"}>Read More</Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section >
        </>
    )
}
