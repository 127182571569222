import React, { useContext, useEffect, useRef, useState } from 'react'
import Contact from '../assets/images/contact.jpg';
import { Link } from 'react-router-dom';
import HTTP from "../Http_Api";
import dataContext from '../context/data/dataContext';
export default function Target() {

    const context = useContext(dataContext);
    const { data, multi_image_url } = context
    const windoSCrollCount = useRef(0)
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop


    }, [])

    var mission;
    if (data.length > 0) {
        var { mission } = data[0];
    }

    return (

        <>

            <>
                <section id="page-banner">
                    <div className="banner-inner"
                        style={{ backgroundImage: `url(https://source.unsplash.com/random/400x400/?islamic)` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner-contents">
                                        <h1 className='d-block'>লক্ষ্য</h1>
                                        <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                            <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> হোম</Link>
                                            {/* <Link to={'/'} className="breadcrumbs__item">Shop</Link> */}
                                            {/* <Link to={'/'} className="breadcrumbs__item">Cart</Link> */}
                                            <Link to={'/target'} className="breadcrumbs__item is-active">লক্ষ্য</Link>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {mission !== undefined && (mission).map((data) => (
                    <section id="history">
                        <div className="container" >
                            <h3 className='mt-5 text-center'>{data.title !== null && data.title}</h3>


                            <div style={{ display: "inline-block", marginBottom: "3rem" }}>
                                <div>
                                    {data.photo !== null && data.photo.length > 0 &&
                                        <img className=' ' style={{ borderRadius: "5%", float: "left", height: "18rem", width: "31rem", paddingRight: "1rem", paddingBottom: "0rem" }} src={`${multi_image_url}/uploads/target/${data.photo}`} alt="" />
                                    }

                                    {data.message !== null && data.message.length > 0 &&
                                        <p className='my-5' style={{ textAlign: "justify" }}>{data.message}</p>
                                    }

                                </div>
                            </div>

                        </div>

                    </section>
                ))}
            </>

        </>
    )
}
