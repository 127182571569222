import React, { useContext, useEffect, useRef, useState } from 'react'
import Contact from "../assets/images/contact.jpg";
import { Link } from 'react-router-dom';
import Http from '../Http_Api';
import dataContext from '../context/data/dataContext';
export default function ContactUs() {
    const windoSCrollCount = useRef(0)
    const context = useContext(dataContext);
    const { data } = context
    const [dataContactUs_title, setDataContactUs_title] = useState([])
    const [dataContactUs_inner, setDataContactUs_inner] = useState([])
    const [dataContactUs_map, setDataContactUs_map] = useState([])
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }

    }, [])

    var phone, email, address, google_map;
    if (data.length > 0) {
        var { phone, email, address, google_map } = data[0];
    }
    return (
        <>

            <section id="page-banner">
                <div className="banner-inner"
                    style={{ backgroundImage: `url(${Contact})` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div key={''} className="banner-contents">
                                    <h1 className='d-block'>যোগাযোগ</h1>
                                    <nav class="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} class="breadcrumbs__item"><i class="text-black fa-solid fa-house"></i> হোম</Link>
                                        {/* <Link to={'/'} class="breadcrumbs__item">Shop</Link> */}
                                        {/* <Link to={'/'} class="breadcrumbs__item">Cart</Link> */}
                                        <Link to={'/contactUs'} class="breadcrumbs__item is-active">যোগাযোগ</Link>
                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="boxs">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="grid-boxs " style={{ display: "flex", justifyContent: "center" }}>


                                {phone !== undefined && phone !== null &&
                                    <div className="single-grid-box">
                                        <div className="box-icon">
                                            <i className="fa fa-phone-alt"></i>
                                        </div>
                                        <div className="box-contents">
                                            <h3>মোবাইল</h3>
                                            <ul>
                                                <li>শিক্ষা সচিব : {phone}</li>
                                                {/* <li>শিক্ষা সচিব : (+৮৮) ০১৯৩৩-৭৯৪৬১৬</li>
                                                <li>নির্বাহী পরিচালক : (+৮৮) ০১৭২১-৫৭১০৪০</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {email !== undefined && email !== null &&

                                    <div className="single-grid-box">
                                        <div className="box-icon">
                                            <i className="fa fa-phone-alt"></i>
                                        </div>
                                        <div className="box-contents">
                                            <h3>ই-মেইল</h3>
                                            <ul>
                                                <li><a href={`mailto:${email}`}>{email}</a>
                                                </li>
                                                {/* <li><a href="mailto:info@markajutalimisunnah.com">info@markajutalimisunnah.com</a>
                                                </li>
                                                <li><a
                                                    href="mailto:contact@markajutalimisunnah.com">contact@markajutalimisunnah.com</a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {address !== undefined && address !== null &&

                                    <div className="single-grid-box">
                                        <div className="box-icon">
                                            <i className="fa fa-phone-alt"></i>
                                        </div>
                                        <div className="box-contents">
                                            <h3>ঠিকানা</h3>
                                            <ul>
                                                <li>{address}</li>
                                                {/* <li>রশিদপুর, জামালপুর পৌরসভা, জামালপর সদর,</li>
                                                <li>জামালপুর - ২০০০, বাংলাদেশ</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                {/* <div className="single-grid-box">
                                    <div className="box-icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    <div className="box-contents">
                                        <h3>ক্লাসে সময়</h3>
                                        <ul>
                                            <li>শনি-বৃহ: সকাল ৮ টা থেকে বিকাল ৪ টা</li>
                                            <li>শুক্র: সকাল ৮ থেকে সকাল ১০ টা</li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="map">
                {google_map !== undefined && google_map !== null && google_map.length > 0 ?
                    <div className="map-iframe">
                        <iframe title='map'
                            src={google_map}
                            width=""
                            height=""
                            style={{ border: '0' }}
                            allowfullscreen=""
                            loading="lazy"></iframe>
                    </div>
                    :
                    <>
                        <div className="map-iframe">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7497570.975902517!2d85.04471258115709!3d23.42713667298836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30adaaed80e18ba7%3A0xf2d28e0c4e1fc6b!2sBangladesh!5e0!3m2!1sen!2sbd!4v1702932580787!5m2!1sen!2sbd" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                    </>

                }
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="contact-form-inner">
                                <h4>আমাদের সাথে যোগাযোগ</h4>
                                <div className="contact-form">
                                    <div className="single-input">
                                        <input type="text"
                                            placeholder="Name" />
                                    </div>
                                    <div className="single-input">
                                        <input type="email"
                                            placeholder="Email" />
                                    </div>
                                    <div className="single-input">
                                        <input type="text"
                                            placeholder="Subject" />
                                    </div>
                                    <div className="single-input">
                                        <textarea name=""
                                            id=""
                                            cols=""
                                            rows=""
                                            placeholder="Message"></textarea>
                                    </div>
                                    <div className="single-input-btn">
                                        <input type="submit"
                                            value="Send" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
