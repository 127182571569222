import React, { useEffect, useRef, useState } from 'react'
import donation_png from "../assets/images/donation-png.png"
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
export default function Donation() {
    const windoSCrollCount = useRef(0)
    const func_Call_Count = useRef(0);
    const [phone, setPhone] = useState('')
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            // console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        document.getElementsByClassName('react-tel-input')[0].getElementsByTagName('input')[0].style.width = "100%";
        document.getElementsByClassName('react-tel-input')[0].getElementsByTagName('input')[0].classList.add('form-control', 'border-dark',);
        document.getElementsByClassName('react-tel-input')[0].getElementsByClassName('selected-flag')[0].classList.add('form-control', 'border-dark',);
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
    })
    const cheacked_input_vissible = () => {
        let cheacked_input = document.getElementsByClassName('cheacked_input')[0];
        if (cheacked_input.classList.contains('d-none')) {
            cheacked_input.classList.remove('d-none');
            cheacked_input.classList.add('d-block');
        }
        else {
            cheacked_input.classList.add('d-none');
            cheacked_input.classList.remove('d-block');
        }
    }
    const Add_More_Donation = (e) => {
        let main_box = document.getElementById('main_box');
        let add_more_donate = document.getElementById('add_more_donate');
        let donation_type = document.getElementById('donation_type')
        let donation_cutter = document.getElementsByClassName('donation_cutter')
        if (main_box != null) {
            let lastElementChild = main_box.lastElementChild
            let clone = lastElementChild.cloneNode(true);
            clone.getElementsByClassName('donation_cutter')[0].parentElement.classList.remove('d-none')
            clone.getElementsByClassName('donation_cutter')[0].parentElement.classList.add('d-block')
            if (main_box.childElementCount < donation_type.length - 1) {
                main_box.appendChild(clone)
            }
            if (main_box.childElementCount == donation_type.length - 1) {
                add_more_donate.classList.add('d-none')
            }
            for (let index = 1; index < donation_cutter.length; index++) {
                const element = donation_cutter[index];
                element.addEventListener('click', (e) => {
                    let removeElement = e.target.closest('.form-box')
                    removeElement.remove()
                    if (add_more_donate.classList.contains('d-none')) {
                        add_more_donate.classList.remove('d-none')
                    }
                    // console.log('')
                })
            }

        }
    }
    const [phoneVaildateMsg, setPhoneVaildateMsg] = useState()
    const validateMyForm = (e) => {
        e.preventDefault()
        console.log('fasdf');

        if (phone !== undefined) {
            if (phone.length === 13) {
                console.log('fasdf')
                setPhoneVaildateMsg('')
            }
            else {
                setPhoneVaildateMsg("Please type a valid phone number");
            }
        }
        else {
            setPhoneVaildateMsg("Please type a valid phone number");
        }
    }

    return (
        <section id="donation">
            <div className="container  my-5">

                <div className="row ">
                    <div className="col-lg-6 text-center ">
                        <div className='p-5' style={{ background: "#007c32", borderRadius: "1rem" }}>
                            <img width={"80%"} className='' src={donation_png} alt="" />
                        </div>
                    </div>
                    <div className="mygap col-lg-6 px-4">
                        {/* <h3>অনুদান</h3> */}
                        <form onSubmit={validateMyForm}>
                            <div id="main_box" className='mx-auto text-center' style={{ padding: "0 1.5rem", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div className="border w-full form-box d-flex align-content-center justify-content-center text-center ">
                                    <div className="min_box mb-3 mr-2 font-weight-bold p-1  ">
                                        <label for=""
                                            className="form-label px-1 py-1 bg-green-primary text-white w-100 text-center font-weight-bold">অনুদানের প্রকার
                                        </label>
                                        <div className="p-3">
                                            <select className="form-select form-control "
                                                name=""
                                                id="donation_type">
                                                <option selected>অনুদান প্রকার</option>
                                                <option value="">New Delhi</option>
                                                <option value="">Istanbul</option>
                                                <option value="">Jakarta</option>
                                                <option value="">Jakarta</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="min_box mb-3 mr-2 font-weight-bold p-1  ">
                                        <label for=""
                                            className="form-label px-1 py-1 bg-green-primary text-white w-100 text-center font-weight-bold">পরিমান</label>
                                        <div className="p-3 d-flex">
                                            <input type="text"
                                                disabled
                                                placeholder="9999"
                                                className="form-control" />
                                            <span
                                                style={{ fontSize: "1.5rem", paddingBottom: "0.1rem" }}
                                                className="ml-3 mb-0 text-center   btn btn-sm btn-danger d-none  "><i
                                                    id="donation_cutter" className="fa fa-times-circle donation_cutter m-0"
                                                    data-v-9ca7e832=""></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center my-5" >
                                <span id="add_more_donate" onClick={(e) => { Add_More_Donation(e) }}
                                    className="btn btn-success px-lg-5 py-3 m-0 font-weight-bolder"><i className="fa fa-plus-circle "
                                        data-v-9ca7e832=""></i><span className='px-1'>আরো যোগ করুন</span></span>
                            </div>
                            {/* <div className="mb-3  mx-auto" style={{ padding: "0 1.5rem" }}>
                                <textarea className="form-control border-dark"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Enter your message"></textarea>
                            </div> */}
                            {/* <div className="text-center my-5" >
                        <span id="add_more_donate" onClick={(e) => { Add_More_Donation(e) }}
                            className="btn btn-success px-lg-5 py-3 m-0 font-weight-bolder"><i className="fa fa-plus-circle "
                                data-v-9ca7e832=""></i>Add More</span>
                    </div> */}

                            {/* <div style={{marginLeft:"1.5rem"}} className="mx-1" id="form-check"
                                className="form-check mb-4">
                                <input className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="dontaion_cheakbox" onClick={cheacked_input_vissible} />
                                <label className="form-check-label"
                                    for="">
                                    I WANT TO PROVIDE MY INFORMATION
                                </label>
                            </div> */}
                            <div style={{ padding: "0 1.5rem" }} className="cheacked_input mt-3 d-block">

                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label for=""
                                            className="form-label">নাম</label>
                                        <input type="text"
                                            className="form-control border-dark"
                                            id=""
                                            aria-describedby="emailHelp" required />
                                    </div>
                                </div>
                                {/* <div className="col col-lg-6">
                                        <div className="mb-3">
                                            <label for=""
                                                className="form-label border-dark">Phone</label>
                                            <input type="tel"
                                                className="form-control border-dark"
                                                id=""
                                                aria-describedby="emailHelp" required />
                                            <PhoneInput
                                                
                                                placeholder="Enter phone number"
                                                value={phone}
                                                onChange={setPhone} />
                                        </div>
                                    </div> */}
                                {/* </div> */}
                                <div className="mb-3 ">
                                    <label for=""
                                        className="form-label"><span className='text-danger'>*</span>মোবাইল </label>
                                    <PhoneInput
                                        country={'bd'}
                                        value={phone} style={{ width: "100%" }}
                                        onChange={setPhone} />
                                    <div id="phoneHelp"
                                        className="form-text text-danger">{phoneVaildateMsg}</div>
                                </div>
                                <div className="mb-3">
                                    <label for=""
                                        className="form-label">ইমেইল</label>
                                    <input type="email"
                                        className="form-control border-dark"
                                        id=""
                                        aria-describedby="emailHelp" required />
                                    <div id="emailHelp"
                                        className="form-text"></div>
                                </div>
                                <div className="mb-3">

                                    <textarea className="form-control border-dark"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        placeholder="Enter your message"></textarea>
                                    <div id="emailHelp"
                                        className="form-text"></div>
                                </div>
                                    
                            </div>
                            <div className="text-center mb-5">
                                <button type="submit"
                                    className="btn btn-lg btn-success w-50 font-weight-bold text-white py-3">দান করুন</button>
                            </div>
                            <p className='text-danger'>Note: * চিহ্নিত ঘরগুলো পূরণ করা বাধ্যতামূলক, অন্যান্যগুলো ঐচ্ছিক</p>
                        </form>
                    </div>


                </div>
            </div>
        </section >
    )
}
