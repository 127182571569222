import jQuery from "jquery";
import $ from "jquery"
(function ($) {
    "use strict";



$(function () {


    /*-------------------------------------
          Calender initiate 
      -------------------------------------*/
    if ($.fn.fullCalendar !== undefined) {
        $('#fc-calender').fullCalendar({
            header: {
                center: 'basicDay,basicWeek,month',
                left: 'title',
                right: 'prev,next',
            },
            fixedWeekCount: false,
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            eventLimit: true, // allow "more" link when too many events
            aspectRatio: 1.8,
            events: [{
                title: 'All Day Event',
                start: '2019-04-01'
            },

            {
                title: 'Meeting',
                start: '2019-04-12T14:30:00'
            },
            {
                title: 'Happy Hour',
                start: '2019-04-15T17:30:00'
            },
            {
                title: 'Birthday Party',
                start: '2019-04-20T07:00:00'
            }
            ]
        });
    }
});

})(jQuery);