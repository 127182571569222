import React, { useContext, useEffect, useState } from 'react';

import Footer_logo from "../assets/images/footer-logo.png";
import Cards from "../assets/images/cards.png";
import Cards_2 from "../assets/images/Cards_2.png"
import Http from '../Http_Api';
import dataContext from '../context/data/dataContext';
import { Link } from 'react-router-dom';
export default function Footer() {
    const context = useContext(dataContext);
    const { data, multi_image_url } = context
    const [data_footer_logo, setData_footer_logo] = useState([]);
    const [data_footer_address, setData_footer_address] = useState([]);
    const [data_footer_copyright, setData_footer_copyright] = useState([]);
    const [data_footer_social, setData_footer_social] = useState([]);
    useEffect(() => {
        // get_data_footer_inner();
    }, [])

    // const get_data_footer_inner = async () => {
    //     await Http.get('/article/footer-top/logo').then((res, req) => {
    //         let value = [];
    //         Object.keys(res.data).map((key) => {
    //             value.push(res.data[key]);
    //         })
    //         if (value.length > 0) {
    //             setData_footer_logo(value);
    //         }
    //     })

    //     await Http.get('/article/footer-top/address').then((res, req) => {
    //         let value = [];
    //         Object.keys(res.data).map((key) => {
    //             value.push(res.data[key]);
    //         })
    //         if (value.length > 0) {
    //             setData_footer_address(value);
    //         }
    //     })

    //     await Http.get('/article/footer-bottom/copyright').then((res, req) => {
    //         let value = [];
    //         Object.keys(res.data).map((key) => {
    //             value.push(res.data[key]);
    //         })
    //         if (value.length > 0) {
    //             setData_footer_copyright(value);
    //         }
    //     })

    //     await Http.get('/article/footer-bottom/social').then((res, req) => {
    //         let value = [];
    //         Object.keys(res.data).map((key) => {
    //             value.push(res.data[key]);
    //         })
    //         if (value.length > 0) {
    //             setData_footer_social(value);
    //         }
    //     })
    // }

    var school_name, email, address, google_map, facebook_url, facebook_url, phone, linkedin_url, google_plus_url, twitter_url, instagram_url, footer_logo;

    if (data.length > 0) {
        var { school_name, email, google_map, address, facebook_url, phone, linkedin_url, google_plus_url, twitter_url, instagram_url, footer_logo } = data[0];
    }

    return (
        <>
            <footer>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">

                                {footer_logo !== undefined && footer_logo !== null && footer_logo.length > 0 &&
                                    <div className="footer-logo" style={{width:"15rem",margin:"auto"}}>
                                        <Link to={'/'} style={{minHeight:"100%",minWidth:"100%"}}> <img style={{minWidth:"100%",minHeight:"100%"}} src={`${multi_image_url}/uploads/logo/${footer_logo}`}
                                            alt="" /></Link>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="address">
                                    <h5>ঠিকানা</h5>
                                    <ul>
                                        {address !== undefined && address !== null && address.length > 0 &&
                                            <li className='d-flex align-items-center '><i className="fa fa-map-marker-alt"></i><a target='_blank'
                                                href={`${google_map !== null && google_map.length > 0 && google_map}`}>{address} </a></li>
                                        }
                                        {email !== undefined && email !== null && email.length > 0 &&
                                            <li className='d-flex align-items-center '><i className="fa fa-envelope"></i><a
                                                href={`mailto:${email}`}>{email}</a></li>
                                        }
                                        {phone !== undefined && phone !== null && phone.length > 0 &&
                                            <li className='d-flex align-items-center '><i className="fa fa-phone-alt"></i><a href={`tel:+88${phone}`}>+88{phone}</a> </li>
                                        }
                                        {/* <li><i className="fa fa-globe-asia"></i><a href="#">www.markajutalimissunnah.com</a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="quick-link">
                                    <h5>দ্রত লিঙ্ক</h5>
                                    <ul>
                                        <li><Link to={"#"}>ভর্তি তথ্য</Link></li>
                                        <li><Link to={'/NewsAll'}>নিউজ</Link></li>
                                        <li><Link to={"/EventAll"}>ইভেন্ট</Link></li>
                                        <li><Link to={"#"}>জিজ্ঞাসা</Link></li>
                                        <li><Link to={'/contactUs'}>যোগাযোগ</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="cards">
                                    {/* <h5>এই সকল মূল্যপরিশোধ পদ্ধতিতে মূল্যপরিশোধ করা যাবে</h5> */}
                                    <img src={Cards_2}
                                        alt="" />
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="cards">
                                    <h5>এই সকল মূল্যপরিশোধ পদ্ধতিতে মূল্যপরিশোধ করা যাবে</h5>
                                    <img src={Cards}
                                        alt="" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-4 col-12">
                                {school_name !== undefined && school_name !== null && school_name.length > 0 &&
                                    <div className="copyright">
                                        <p>কপিরাইট {(new Date().getFullYear())} {school_name}  - সর্বসত্ত¡ সংরক্ষিত <br /> Design and Developed by <b><a href="https://webshinez.com" style={{ color: "#888" }}>Web Shinez Technology</a> </b></ p>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="footer-policy">
                                    <ul>
                                        <li><a href="#">শর্তাতলী</a></li>
                                        <li><a href="#">গোপনীয়তা নীতি</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="socials">
                                    <ul>
                                        {data_footer_social.map((data) => (
                                            <li key={data.content_id}><a href="#"><i className={data.css_class}></i></a></li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="socials">
                                    <ul>
                                        {facebook_url !== undefined && facebook_url !== null && facebook_url.length > 0 ? (<li className='mx-1'><a href={facebook_url}><i className="fab fa-facebook-f"></i></a></li>) : null}
                                        {twitter_url !== undefined && twitter_url !== null && twitter_url.length > 0 ? (<li className='mx-1'><a href={twitter_url}><i className="fab fa-twitter"></i></a></li>) : null}
                                        {linkedin_url !== undefined && linkedin_url !== null && linkedin_url.length > 0 ? (<li className='mx-1'><a href={linkedin_url}><i className="fab fa-linkedin-in"></i></a></li>) : null}
                                        {instagram_url !== undefined && instagram_url !== null && instagram_url.length > 0 ? (<li className='mx-1'><a href={instagram_url}><i className="fab fa-instagram"></i></a></li>) : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer >

        </>
    );
}
