import logo from './logo.svg';
import './App.css';
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import Home from "./main_pages/Home.js";
import { Outlet } from 'react-router-dom';
import DataStates from './context/data/DataStates.js';

function App() {


  return (
    <>
      <DataStates>
        <Navbar></Navbar>
        <Outlet />
        <Footer></Footer>
      </DataStates>
    </>
  );
}

export default App;
